import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import {HTMLContentParse} from "../components/Content";

export const AboutPageTemplate = ({
                                    feedback,
                                    title,
                                    subtitle,
                                    text,
                                    image,
                                    quote,
                                    curriculum,
                                  }) => (
  <div className="Content">
    <div className="Article">
      <div className="featured-image" style={{
        backgroundImage: `url(${
          image && image.childImageSharp
            ? image.childImageSharp.fluid.src
            : image
          })`
      }}></div>
      <div className="entry-content">
        <h1 className="entry-title">{title}</h1>
        <div className="entry-lead">{subtitle}</div>
        <HTMLContentParse className="entry-body" content={text} />
      </div>
    </div>
    <div className="Quote">
      <HTMLContentParse className="Quote__entry" content={quote}>
      </HTMLContentParse>
    </div>
    <div className="Article">
      <div className="entry-content">
        <HTMLContentParse className="entry-body" content={curriculum} />
      </div>
    </div>
  </div>
)

AboutPageTemplate.propTypes = {
  feedback: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  quote: PropTypes.string,
  curriculum: PropTypes.string,
  text: PropTypes.string,
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <AboutPageTemplate
        feedback={frontmatter.feedback}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        image={frontmatter.image}
        quote={frontmatter.quote}
        curriculum={frontmatter.curriculum}
        text={frontmatter.text}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        feedback {
          author
          body
          subtitle
        }
        title
        subtitle
        text
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        quote
        curriculum
      }
    }
  }
`
